import React, { useEffect, useState } from "react";
import { ASCII, LOCAL_STORAGE } from "constants/index";
import { ImageWrapper } from "components/tabPanels/media/components/thumbnailColumn/index";
import openNotificationWithIcon from "./notification";
import OutlineMenu from "components/outlineMenu";
import { FAL } from "icons/fa";
import { ARTICLE_FEEDS_ACCESS_ALLOWED_TO } from "constants/index";
import { getFileNameFromUrl } from "components/forms/uploader/utils";
/**
 * Set default object values if they cannot be assigned elsewhere.
 * Useful for when using Flow, setting an Object type, while also
 * needing to assign some defaults that could possibly be undefined,
 * and you need to set default values. Cleaner than a long form series
 * of if statements to resolve a default value.
 * @example
 * const viewer = (query: string, options: Options, type: string) => {
 *   // note: the object must be mutating defaultValues, not a new obj.
 *   const { someValue, otherValue } = Object.assign(defaultValues('foo'), options);
 *   // use the options vars, expect default values
 *   // someValue === 'foo'
 * };
 */
export const defaultValues = (defaultValue) =>
  new Proxy(
    {},
    {
      get: function (object, property) {
        return object.hasOwnProperty(property)
          ? object[property]
          : defaultValue;
      },
    }
  );

const attrMap = {
  allowfullscreen: "allowFullScreen",
  allowtransparency: "allowTransparency",
  autocomplete: "autoComplete",
  autofocus: "autoFocus",
  autoplay: "autoPlay",
  cellpadding: "cellPadding",
  cellspacing: "cellSpacing",
  colspan: "colSpan",
  contenteditable: "contentEditable",
  crossorigin: "crossOrigin",
  datetime: "dateTime",
  frameborder: "frameBorder",
  marginheight: "marginHeight",
  marginwidth: "marginWidth",
  readonly: "readOnly",
  maxlength: "maxLength",
  minlength: "minLength",
  rowspan: "rowSpan",
  srcdoc: "srcDoc",
  srclang: "srcLang",
  srcset: "srcSet",
  tabindex: "tabIndex",
};

type ElementProps = {
  node: any;
  children: any;
};

const camelize = (name) => attrMap[name] || name;

export const Element = ({ node, children }: ElementProps) => {
  const props = (node.attributes || []).reduce((memo, { name, value }) => {
    if (name === "class") {
      memo.className = value.join(" ");
    } else {
      memo[camelize(name)] = value;
    }
    return memo;
  }, {});

  return React.createElement(node.tagName, props, children);
};

type ResizeImageProps = {
  src: string;
  width?: number;
  alt?: string;
  className?: string;
  removeContent?: any;
  editContent?: () => void;
  type?: "file" | "image" | "audio" | "video";
  title?: string;
  altText?: string;
};

export const ResizeImage = ({
  src,
  width,
  removeContent,
  type,
  title = "",
  editContent,
  altText,
  ...rest
}: ResizeImageProps) => {
  const iconName = getIconBasedOnExtension(src);
  return (
    <OutlineMenu
      actions={{
        edit: (e) => {
          e.stopPropagation();
          if (editContent) {
            editContent();
          }
        },
        removeConfirmation: () => {
          if (removeContent) {
            removeContent();
          }
        },
      }}
    >
      {type === "file" && (
        <div className="file-wrapper">
          <FAL icon={`${iconName}`} size="5x" />
          <div className="file-thumb">{title}</div>
        </div>
      )}
      {type !== "file" && (
        <ImageWrapper
          url={src}
          imageSize={width}
          maxWidth="100%"
          maxHeight="200px"
          altText={altText}
        />
      )}
    </OutlineMenu>
  );
};

/**
 * It will return true if given keycode don't belongs to keycode of a-z/A-Z/0-9/-
 *
 * @param {number} charCode
 * @return {boolean}
 */
export const isInValidKeyCodeForSeoUrl = (charCode: number) => {
  const AToZStartRange = 96;
  const AtoZEndRange = 123;
  const isCapAlpha = charCode > AToZStartRange && charCode < AtoZEndRange;

  const zero = 48;
  const nine = 57;
  const isNumber = charCode >= zero && charCode <= nine;

  const dashKeyCode = 45;
  const isDash = charCode === dashKeyCode;

  const colon = 58;
  const isColon = charCode === colon;

  const dot = 46;
  const isDot = charCode === dot;

  return !(isCapAlpha || isNumber || isDash || isColon || isDot);
};

/**
 * URL validation helper function
 * it allows you to pass string to check if it is an absolute or relative url
 *
 * @param {string} str contains a url to validate
 * @param {isRemoteUrl} boolean
 * @param {onlyRelativeUrl} boolean indicates validate for only relative url or not
 * @return {boolean} indicates whether the string is a valid URL
 */
export const isURL = (
  str,
  isRemoteUrl = false,
  onlyRelativeUrl = false,
  allowSingleForwardSlash = false
) => {
  const https = "https://";
  const http = "http://";
  const doubleForwardSlash = "//";
  const singleForwardSlash = "/";

  // Allow a hash for the URL.
  // Front-end of site can handle this however
  if (str === "#" || str.startsWith("#")) return true;

  if (isRemoteUrl) {
    return (
      (str.indexOf(https) === 0 && str.length > https.length) ||
      (str.indexOf(http) === 0 && str.length > http.length)
    );
  }

  if (onlyRelativeUrl) {
    return (
      (str.indexOf(doubleForwardSlash) === 0 &&
        str.length > doubleForwardSlash.length) ||
      (str.indexOf(singleForwardSlash) === 0 &&
        str.length > singleForwardSlash.length)
    );
  }

  return (
    (str.indexOf(https) === 0 && str.length > https.length) ||
    (str.indexOf(http) === 0 && str.length > http.length) ||
    (str.indexOf(doubleForwardSlash) === 0 &&
      str.length > doubleForwardSlash.length) ||
    (str.indexOf(singleForwardSlash) === 0 &&
      str.length > singleForwardSlash.length) ||
    (str.indexOf(singleForwardSlash) === 0 &&
      str.length === singleForwardSlash.length &&
      allowSingleForwardSlash)
  );
};

/**
 * Email validation helper function
 * it allows you to pass string to check if it is valid email address or not
 *
 * @param {string} email contains a str to validate
 * @param {string} moduleName contains module name to valdiate string accordingly
 * @return {boolean} indicates whether the string is a valid email
 */

export const validateEmail = (email: string, moduleName: string = "") => {
  // modules name in which allow multiple dots after @ in email address
  const allowMultipleDotsPattern = [
    "users",
    "events",
    "directories",
    "newsletters",
    "classified",
  ];

  var str =
    allowMultipleDotsPattern.indexOf(moduleName) !== -1
      ? /[a-z0-9._%+-]+@(?:[a-zA-Z0-9-]+\.)+[a-z]+$/g
      : /[a-z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-z]+$/g;
  return str.test(email);
};

/**
 * Check if string has trailing or leading whitespace
 * @param {string} s contains a string to validate
 * @return {boolean} indicates whether the string is a valid string
 */
export function hasWhiteSpace(s: string) {
  const whitespaceChars = [" ", "\t", "\n"];
  return whitespaceChars.some((char) => s.includes(char));
}

/**
 * IP address validation helper function
 * it allows you to pass string to check if it is a valid ip address or not
 *
 * @param {string} ipAddress contains a str to validate
 * @return {boolean} indicates whether the string is a valid ip address
 */

export const validateIPAddress = (ipAddress: string): boolean => {
  var str =
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  return str.test(ipAddress);
};

/**
 * It will break camelcase word into multiple words seperated by space ex. newsletterIssue -> newsletter issue
 *
 * @param {string} word ex: galleryItem converted to "gallery item"
 */
export const splitCamelCaseWord = (word: string) => {
  return word
    .split(/(?=[A-Z])/)
    .map((s) => s.toLowerCase())
    .join(" ");
};

/**
 * Whitespace validation helper function
 * it allows you to pass string to check if it is contain whitespace or not
 *
 * @param {string} value contain string value ex: meta data
 */
export const checkWhiteSpace = (value: string) => {
  return value.indexOf(" ") >= 0;
};
/**
 * allow only positive integers in input box
 *
 * @param {React.KeyboardEvent} event
 */
export const allowOnlyPositiveIntegers = (event: React.KeyboardEvent) => {
  let charCode = event.which ? event.which : event.keyCode;
  const {
    CONTROL_END_RANGE,
    DIGIT_START_RANGE,
    DIGIT_END_RANGE,
    PERIOD,
    PLUS,
    HYPHEN,
  } = ASCII;
  const charCodeNotBelongsToDigitsCodeRange =
    charCode > CONTROL_END_RANGE &&
    (charCode < DIGIT_START_RANGE || charCode > DIGIT_END_RANGE);
  const isPeriodCharCode = charCode === PERIOD;
  const isHyphenCharCode = charCode === HYPHEN;
  const isPlusCharCode = charCode === PLUS;

  if (
    charCodeNotBelongsToDigitsCodeRange ||
    isPeriodCharCode ||
    isHyphenCharCode ||
    isPlusCharCode
  ) {
    event.preventDefault();
    return false;
  }

  return true;
};

/**
 * allow only positive and integer/floating point numbers in input box
 *
 * @param {React.KeyboardEvent} event
 */
export const allowOnlyPositiveNumbers = (event: React.KeyboardEvent) => {
  let charCode = event.which ? event.which : event.keyCode;
  const { CONTROL_END_RANGE, DIGIT_START_RANGE, DIGIT_END_RANGE, PERIOD } =
    ASCII;
  const charCodeNotBelongsToDigitsCodeRange =
    charCode > CONTROL_END_RANGE &&
    (charCode < DIGIT_START_RANGE || charCode > DIGIT_END_RANGE);
  const isNotPeriodCharCode = charCode !== PERIOD;

  if (charCodeNotBelongsToDigitsCodeRange && isNotPeriodCharCode) {
    event.preventDefault();
    return false;
  }

  return true;
};

/**
 * Get file size with appropriate unit
 * @param {number} size
 */
export const getFileSizeWithUnit = (size: number) => {
  if (size > 1000) {
    return `${size / 1000}KB`;
  } else if (size > 0) {
    return `${size}B`;
  }

  return "";
};

/**
 * Calculate word count from passed string
 * @param {string} body
 */
export const calculateWordCount = (body: string) => {
  return body ? body.trim().split(/\s+/).length : 0;
};

/**
 * Get which font-awesome icon use based on file extension
 * @param {string} url - file url
 */
export const getIconBasedOnExtension = (url: string) => {
  const fileName = url.substring(
    url.lastIndexOf("/") + 1,
    url.lastIndexOf("?")
  );
  const extension = fileName.substring(
    fileName.lastIndexOf(".") + 1,
    url.lastIndexOf("?")
  );
  let fileThumb: string;

  switch (extension) {
    case "pdf":
      fileThumb = "file-pdf";
      break;
    case "doc":
    case "docx":
      fileThumb = "file-word";
      break;
    case "xls":
    case "xlsx":
      fileThumb = "file-excel";
      break;
    default:
      fileThumb = "file";
      break;
  }
  return fileThumb;
};

/**
 * copy url on clipboard
 * @param {string} url
 */
export const copyLink = (
  url: string,
  oid?: string,
  blog?: { [key: string]: unknown }
) => {
  if (oid && !blog && !url.includes(oid)) {
    var result = /[^/]*$/.exec(url)[0];
    url = url.replace(result, `${oid}-${result}`);
  }
  const dummy = document.createElement("input");
  document.body.appendChild(dummy);
  dummy.setAttribute("id", "dummy_id");
  (document.getElementById("dummy_id") as HTMLInputElement).value = url;
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
  openNotificationWithIcon(
    {
      type: "success",
      title: "Link copied",
    },
    () => {}
  );
};

export type EnvironmentsType = "stage" | "production";

export function stripEnvFromHost(host) {
  const envParts = host.split("-");
  const env = envParts[envParts.length - 1] as EnvironmentsType;

  if (env === "stage") {
    envParts.pop();
    return envParts.join("-");
  }

  return host;
}

export interface EnvType {
  URI: string;
  X_ENV: EnvironmentsType;
  X_HOST_SHORTNAME: string;
  HOST: string;
  IS_STAGE_URL: boolean;
}

/**
 * Environment variables
 */
export function environmentOptions(): EnvType {
  const host = window.location.host.split(".")[0];
  const xHostHeader = () => {
    const activeHost = localStorage.getItem("active.host");
    const DO_NOT_CHANGE_ME_RESTRICT_CONTINUUM_ADMIN =
      activeHost ?? "continuum-alpha";

    // IMPORTANT: Change LOCAL_SITE if you need to test client data.
    // Does not affect stage or production deployed pointers.
    // DO NOT COMMIT A CHANGE TO LOCAL_SITE.
    const LOCAL_SITE = activeHost ?? "continuum-alpha";
    const envParts = host.split("-");
    const env = envParts[envParts.length - 1] as EnvironmentsType;

    if (host === "localhost:3000") return LOCAL_SITE;
    if (host === "continuum" || host === "continuum-stage")
      return DO_NOT_CHANGE_ME_RESTRICT_CONTINUUM_ADMIN;
    // Remove stage from host name that is returned. Join back any hyphened names.
    if (env === "stage") {
      envParts.pop();
      return envParts.join("-");
    }

    return host;
  };

  /**
   * Returns the environment the API should be using; stage or production.
   * First uses the domain name to parse the environment from its name by hyphen.
   * If the env is not included in the domain name we fallback to the local storage value.
   * If localStorage does not hold our env variable, fallback to production unless it's Continuum-Alpha.
   */
  const xEnvHeader = () => {
    const envParts = host.split("-");
    const env = envParts[envParts.length - 1] as EnvironmentsType;
    const savedLocalEnv = localStorage.getItem(
      `${LOCAL_STORAGE.X_ENV}:${xHostHeader()}`
    ) as EnvironmentsType;
    const defaultEnvHeader =
      xHostHeader() === "continuum-alpha" ? "stage" : "production";

    if (env === "stage") return env;

    if (savedLocalEnv) return savedLocalEnv;

    return defaultEnvHeader;
  };

  /**
   * Allow the switching of our production to stage API.
   * Relies on localStorage to enable.
   */
  function apiDomain(): string {
    const apiStagingEnabled = localStorage.getItem("api.staging.enabled");
    if (apiStagingEnabled === "true") {
      return "https://api-stage.epublishing.com/query";
    }

    return "https://api.epublishing.com/query";
  }

  /**
   * Find out whether url holds -stage or not
   */
  function isStageUrl(): boolean {
    const envParts = host.split("-");
    const env = envParts[envParts.length - 1] as EnvironmentsType;

    return env === "stage";
  }

  return {
    URI: apiDomain(),
    X_ENV: xEnvHeader(),
    X_HOST_SHORTNAME: xHostHeader(),
    HOST: host,
    IS_STAGE_URL: isStageUrl(),
  };
}

/**
 * Browser support check for "Local Storage"
 */
export function localStorageExists(): boolean {
  const mod = "epub-test";
  try {
    localStorage.setItem(mod, mod);
    localStorage.removeItem(mod);
    return true;
  } catch (e: any) {
    return false;
  }
}

/**
 * construct link for related panel redirect url
 * @param {string} typename
 * @param {string} oid
 */

const contentTypes = [
  "Article",
  "Product",
  "Gallery",
  "Company",
  "Event",
  "Poll",
  "EditorialContent",
  "Newsletter",
  "Page",
  "File",
  "Publication",
  "Classified",
  "Video",
  "Audio",
  "Image",
] as const;

export const buildLinkTo = (
  typename: (typeof contentTypes)[number],
  oid: string
) => {
  switch (typename) {
    case "Article":
      return `/editorial/articles/${oid}`;
    case "Product":
      return `/revenue/products/${oid}`;
    case "Gallery":
      return `/editorial/galleries/${oid}`;
    case "Company":
      return `/revenue/directory/${oid}`;
    case "Event":
      return `/revenue/events/${oid}`;
    case "Poll":
      return `/marketing/polls/${oid}`;
    case "EditorialContent":
      return `/editorial/editorial-content/${oid}`;
    case "Publication":
      return `/editorial/publications/${oid}`;
    case "Newsletter":
      return `/editorial/newsletters/${oid}`;
    case "Page":
      return `/editorial/pages/${oid}`;
    case "File":
    case "Audio":
    case "Video":
    case "Image":
      return "/media";
    case "Classified":
      return `/revenue/classifieds/${oid}`;
  }
};

export function removeDuplicates(array) {
  return array.filter((a, b) => array.indexOf(a) === b);
}

/**
 * Check if filterstring contain null value
 * @param {Array<string>} filterString the filterstring value
 */
export const emptyFilterStringExists = (filterString: string[]) => {
  const newValue = filterString?.map(
    (strings: string) => strings.split(" ")[2]
  );

  if (newValue.some((v) => v.includes(null))) return true;
  return false;
};

/**
 * Returns a key based on a value
 *
 * @param {string} object object with keys and values
 * @param {string} value value to use as a filter
 */
export function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value) || value;
}

export function getGeneralPath() {
  const path = "/media";
  const dateObj = new Date();
  const year = dateObj.getFullYear();
  const month = dateObj.getMonth() + 1;
  const strMonth = month < 10 ? `0${month}` : `${month}`;
  const day = dateObj.getDate();
  const strDay = day < 10 ? `0${day}` : `${day}`;

  return `${path}/${year}/${strMonth}/${strDay}`;
}

export function getExtension(file: string) {
  const regexForExt = /(?:\.([^.]+))?$/;
  return regexForExt.exec(file)[1];
}

/**
 * Checks if param is a function, return truthy.
 * @param func The value check against.
 */
export function isFunction(func: unknown): boolean {
  return func && {}.toString.call(func) === "[object Function]";
}

/**
 * Download file
 * @param {string} title file title
 * @param {string} url file url
 */
export function downloadFile(title: string, url: string) {
  const decodedUrl = decodeURIComponent(url);
  const urlParts = decodedUrl.split("?");
  const timeStamp = new Date().getTime();
  const newUrl = `${encodeURI(
    urlParts[0].replace("http:", "https:")
  )}?${timeStamp}`;

  fetch(newUrl, {
    method: "GET",
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error("network error");
      }

      return response.blob().then((blob) => {
        const blobUrl = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = blobUrl;
        const fileName = getFileNameFromUrl(url);
        a.download = decodeURI(fileName);
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
    })
    .catch((error) => {
      console.error("Error", error);
    });
}

/**
 * Checks country is canada or united states, return truthy.
 * @param country The value check against.
 */
export function checkCountry(country) {
  const validCountries = new Set([
    "CAN",
    "CA",
    "US",
    "Canada",
    "United States",
  ]);
  return validCountries.has(country);
}

/**
 * Generate Location Markup
 * @param record User Record
 * @returns JSX Partial that formats Location Correctly
 */
export const locationString = (record: any) => {
  let results = [record.city];

  checkCountry(record.countryName)
    ? results.push(record.stateName)
    : results.push(record.province);

  return (
    <>
      {results.filter((n) => n).join(", ")}
      <br />
      {record.countryName}
    </>
  );
};

/**
 * Checks password contain number and character.
 * @param pwd The value check against.
 */
export function validatePassword(pwd) {
  const regexForPwd = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\w\W]{8,}$/;
  return regexForPwd.test(pwd);
}

/**
 * convert html to string
 * @param {string} html
 */
export function htmlToText(html: string) {
  const temp = document.createElement("div");
  temp.innerHTML = html;
  return temp.textContent;
}

export function isEmpty(item) {
  switch (typeof item) {
    case "object":
      return Object.keys(item).length === 0;
    default:
      throw Error("Type of item passed to isEmpty is not validated.");
  }
}

/**
 * Check start IP range is less than end IP range
 * @param {Object} ipRange contain start and end IP address value
 */
export function isIPRangeValid(ipRange) {
  const pad = (num) => String("00" + num).slice(-3);
  const ip1 = ipRange.beginIpAddress
    .split(".")
    .map((num) => pad(num))
    .join(".");
  const ip2 = ipRange.endIpAddress
    .split(".")
    .map((num) => pad(num))
    .join(".");
  return ip1 <= ip2;
}

/**
 * find out if current client can access to article feeds or not
 */
export function isArticleFeedsAllowed() {
  const host = window.location.host.split(".")[0];
  return (
    ARTICLE_FEEDS_ACCESS_ALLOWED_TO.indexOf(
      stripEnvFromHost(host).toLowerCase()
    ) !== -1
  );
}

/**
 * Checks state name contain character.
 * @param stateName The value check against.
 */
export function isValidStateName(stateName: string) {
  const regexForStateName = /^[A-Za-z]{2,2}$/;
  return regexForStateName.test(stateName);
}

/**
 * Return string in format of first letter uppercase and others char in lowercase i.e Publication
 * @param str The value contains string.
 */
export function titleCase(str: any) {
  return str
    .split(" ")
    .map((item) => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase())
    .join(" ");
}

/**
 * Return string in format of strips HTML tags from a string
 * @param str The value contains string.
 */
export function stripHTML(str: string) {
  return str.replace(/(<([^>]+)>)/gi, "");
}

/**
 * check integer/floating point numbers in input box
 *
 * @param val the value contains number
 */
export const checkDecimalNumber = (val) => {
  let regexPattern = /^[0-9]*\.?[0-9]*$/;

  let value = val.replace(/ USD|,/g, "");

  if (regexPattern.test(value)) {
    return true;
  } else {
    return false;
  }
};

/**
 * Trims text to a certain number of letters.
 *
 * @param text Text to trim
 * @param num_letters Number of letters. Default '20'
 * @param more What to append if text needs to be trimmed. Default '…'
 */
export const trim_letters = (text, num_letters = 20, more = "...") => {
  let truncatedText = text.substring(0, num_letters);

  if (text.length > num_letters) {
    return truncatedText + more;
  } else {
    return truncatedText;
  }
};
/**
 * Check local storage item which indicate of authentication from sibling site
 */
export const isLoginFromSiblingSite = () => {
  return Boolean(localStorage.getItem("login-from-sibling-site"));
};

/**
 * Remove login from sibling site flag from local storage
 */
export const clearLoginFromSiblingSite = () => {
  localStorage.removeItem("login-from-sibling-site");
};

/**
 * check provided string is date
 *
 * @param val the value contains string
 */
export const checkIsDate = (val) => {
  let regexPattern = /^\d{2}\/\d{2}\/\d{4}$/;
  let value = val.trim();

  return regexPattern.test(val.trim());
};

/**
 * Convert uiStyle string to array with style properties
 *
 * @param {string} style style string
 */
export const uiStyleToArray = (style) => {
  return JSON.parse(style.replace(/=>/g, ":"));
};

/**
 * ENR is BNP's biggest brand. Optimized accordlingly
 * @param context {host, siblings}
 * @returns true/false
 */
export const isBNP = (context: any) => {
  return context?.host === 'enr' || context?.siblings?.includes('enr');
}

/**
 * deepFlatten is custom function to shift only child array with parent level array for export csv
 * @param { Row } row contains manipulated csv order data
 * @param { number } headerLength contains header length
 */
export const deepFlatten = (
  row: string[] | string[][],
  headerLength: number
): string[] => {
  const flatList = [];
  row.forEach((item) => {
    Array.isArray(item) && item.length !== headerLength
      ? flatList.push(...item) // extract order items
      : flatList.push(item);
  });
  return flatList;
};

export const dateRangeCorrection = (value): string[] | null => {
  // Return null if value is undefined or an empty array
  if (!value || value.length === 0) return null;

  // Helper function to convert a date string to a UTC Date object
  const toUTCDate = (dateStr) => {
    const dt = new Date(dateStr);
    // Return a new Date object using UTC year, month, and date to strip the local timezone
    return new Date(dt.getUTCFullYear(), dt.getUTCMonth(), dt.getUTCDate());
  };

  // Convert both dates in the value array to UTC dates
  let [date1, date2] = value.map(toUTCDate);

  // Add one day to the second date
  date2.setUTCDate(date2.getUTCDate() + 1);

  // Helper function to format a Date object as "yyyy-MM-dd"
  const formatDate = (date) => {
    const year = date.getUTCFullYear(); // Get the year in UTC
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Get the month (0-indexed) and pad it with a leading zero if necessary
    const day = String(date.getUTCDate()).padStart(2, '0'); // Get the day and pad it with a leading zero if necessary
    return `${year}-${month}-${day}`; // Construct the formatted date string
  };

  // Return the formatted date strings as an array
  return [formatDate(date1), formatDate(date2)];
};


// Extend the String interface
declare global {
  interface String {
    toKebabCase(): string;
  }
  interface Date {
    navDateFormat(): string;
  }
}

String.prototype.toKebabCase = function () {
  return this.replace(/\s+/g, "-") // Replace spaces with dashes
    .replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`) // Add dash before uppercase letters and convert them to lowercase
    .replace(/[_]+/g, "-") // Replace underscores with dashes
    .replace(/--+/g, "-") // Replace multiple dashes with a single one
    .replace(/(^-|-$)/g, "") // Remove leading and trailing dashes
    .toLowerCase(); // Convert the entire string to lowercase
};

export const usePersistedState = (query, defaultState) => {
  const key = query?.definitions?.[0]?.name?.value;

  const getInitialState = () => {
    try {
      const storedState = localStorage.getItem(key);
      if (storedState) {
        return JSON.parse(storedState);
      }
    } catch (error) {
      console.error(`Error loading localStorage key "${key}":`, error);
      // Remove the offending item from localStorage
      localStorage.removeItem(key);
    }
    return defaultState;
  };

  const [state, setState] = useState(getInitialState);

  useEffect(() => {
    try {
      localStorage.setItem(key, JSON.stringify(state));
    } catch (error) {
      console.error(`Error saving to localStorage key "${key}":`, error);
      // Handle saving errors, if needed (e.g., exceeding storage quota)
    }
  }, [key, state]);

  return [state, setState];
};

/**
 * Format date to navigation format
 * @param {Date} date
 */
Date.prototype.navDateFormat = function() {
  return this.toLocaleString(undefined, {hour: 'numeric', minute: 'numeric', month: 'numeric', day: 'numeric', year: 'numeric'});
};